export function rigFormSubmitButtonDoubleClickBlock(): void {
    const forms = document.querySelectorAll("form:not([method='get'])");
    for (const form of Array.from(forms)) {
        form.addEventListener("submit", () => {
            const btn = form.querySelector(".btn[type='submit']");
            if (!btn) {
                return true;
            }
            btn.setAttribute("disabled", "disabled");
            return true;
        });
    }
}
