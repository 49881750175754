import * as Sentry from "@sentry/browser";

const dsn = document.getElementsByTagName("body")[0].getAttribute("data-sentryDsnForStaticFiles");
if (dsn) {
    Sentry.init({
        enabled: location.hostname !== "localhost",
        dsn,
    });
}

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap";
import "popper.js";
import { ready } from "../utils";
import { rigFormSubmitButtonDoubleClickBlock } from "../utils/block-dbclick-btn-form";

// eslint-disable-next-line @typescript-eslint/require-await
async function rig(): Promise<void> {
    rigFormSubmitButtonDoubleClickBlock();
}

ready(rig);
