export function ready(fn: () => Promise<void>): void {
    if (document.readyState !== "loading") {
        fn().catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
        });
    } else {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export function mapNodeList<T extends Node, R>(
    l: NodeListOf<T>,
    fn: (el: T, i: number) => R,
): R[] {
    const res: R[] = [];
    Array.prototype.forEach.call(l, (el: T, i: number) => {
        res.push(fn(el, i));
    });
    return res;
}

export function tagAttr(el: Element | undefined, name: string, defaultVal?: string): string {
    let res: string | undefined | null;
    if (el !== undefined) {
        res = el.getAttribute(name);
    }
    if (res === undefined || res === null) {
        if (typeof defaultVal !== "undefined") {
            return defaultVal;
        } else {
            throw new Error(`Can't find attr ${name}`);
        }
    }
    return res;
}

export function parseBool(str: string): boolean {
    return str.length > 0 && str !== "no" && str !== "false";
}

export function retrieveElement(container: Element, selector: string, remove?: boolean): Element {
    const el = container.querySelector(selector);
    if (el === null) {
        throw new Error(`Can't find element with selector ${selector}.`);
    }
    if (remove === true) {
        container.removeChild(el);
    }
    return el;
}

export function addClass(el: Element, className: string): void {
    if (el.classList) {
        el.classList.add(className);
    } else {
        el.className += ` ${className}`;
    }
}

export function removeClass(el: Element, className: string): void {
    if (el.classList) {
        el.classList.remove(className);
    } else {
        el.className = el.className.replace(
            new RegExp(`(^|\\b)${className.split(" ").join("|")}(\\b|$)`, "gi"),
            " ",
        );
    }
}

export function hasClass(el: Element, className: string): boolean {
    if (el.classList) {
        return el.classList.contains(className);
    } else {
        return new RegExp(`(^| )${className}( |$)`, "gi").test(el.className);
    }
}

export function toggleClass(
    el: Element,
    fromClass: string,
    toClass: string,
    onFrom?: () => void,
    onTo?: () => void,
): void {
    if (hasClass(el, fromClass)) {
        removeClass(el, fromClass);
        addClass(el, toClass);
        if (onTo !== undefined) {
            onTo();
        }
    } else {
        removeClass(el, toClass);
        addClass(el, fromClass);
        if (onFrom !== undefined) {
            onFrom();
        }
    }
}

export function defined<T>(val: T | undefined | null, defaultVal?: T): T {
    if (val === undefined || val === null) {
        if (defaultVal !== undefined) {
            return defaultVal;
        } else {
            throw new Error("undefined");
        }
    }
    return val;
}

export function pad(n: number | string, width: number, z?: string): string {
    z = z === undefined ? "0" : z;
    n = `${n}`;
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function dispatchEvent(el: Element, name: string): void {
    const event = document.createEvent("Event");
    event.initEvent(name, true, false);
    el.dispatchEvent(event);
}

export function reportPromiseError<T>(promise: Promise<T>): void {
    promise.catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
    });
}

export function parseBoolAttr(str: string): boolean {
    return str.length > 0 && str !== "no" && str !== "false";
}

export async function delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => window.setTimeout(resolve, ms));
}

// export interface EventEmitter<V> {
//     emit(data: V): void;
//     on(event: { new(): V }, cb: (data: V) => void): void;
// }

// export class SimpleEmitter<V> implements EventEmitter<V> {
//     private listeners: Map<string, Array<(data: V) => void>> =
//         new Map<string, Array<(data: V) => void>>();

//     public emit(data: V): void {
//         const eventname = data.constructor.name;
//         if (!this.listeners.has(eventname)) { return; }
//         for (const l of this.listeners.get(eventname)) {
//             l(data);
//         }
//     }

//     public on(event: { new(): V }, cb: (data: V) => void): void {
//         const eventname = event.name;
//         if (!this.listeners.has(eventname)) {
//             this.listeners.set(eventname, [cb]);
//         } else {
//             this.listeners.get(eventname).push(cb);
//         }
//     }
// }

export function readCookie(name: string): string | undefined {
    const nameStr = `${name}=`;
    document.cookie.split(";").forEach((item) => {
        const pureItem = item.trim();
        if (pureItem.startsWith(nameStr)) {
            return pureItem.substring(nameStr.length, pureItem.length);
        } else {
            return;
        }
    });
    return undefined;
    // const matches = document.cookie.match(
    //     new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`),
    // );
    // return matches !== null ? decodeURIComponent(matches[1]) : undefined;
}

export function setIndefiniteCookie(name: string, value: string): void {
    const str = `${name}=${value}; expires=Fri, 31 Aug 9999 23:59:59 UTC; path=/`;
    document.cookie = str;
}

export function requireElementById<T extends HTMLElement>(id: string): T {
    const el = document.getElementById(id);
    if (!el) {
        throw new Error(`no element with id '${id}'`);
    }
    return el as T;
}

export async function retryUntilDone<R>(promiseFactory: () => Promise<R>): Promise<R> {
    try {
        return await promiseFactory();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
        return delay(2000).then(async () => retryUntilDone(promiseFactory));
    }
}

export function parseInt32NumberForRead(value: number): number {
    if (value > 2147483647) {
        return (4294967296 - value) * -1;
    }
    return value;
}

export function parseInt32NumberForWrite(value: number): number {
    if (value < 0) {
        return 4294967296 + value;
    }
    return value;
}

export function validateEmail(email: string): boolean {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function ensure<T>(value: T | undefined): T {
    if (value === undefined || value === null) {
        throw new Error("value must be defined");
    }
    return value;
}

export interface HTMLIFrameElement extends HTMLElement {
    contentWindow: {
        document: {
            body: HTMLElement;
        };
        location: {
            pathname: string;
        };
    };
}

export interface WindowExtension {
    frameLoadCallback?: (init: () => void) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ResizeObserver?: any;
}
